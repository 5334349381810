var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showNow
    ? _c(
        "div",
        { staticClass: "back-color-black35 flex-row-center cover" },
        [
          _c("div", { staticClass: "dialogArea back-color-white flex-col" }, [
            _c(
              "div",
              {
                staticClass: "title flex-row-center-jx flex-between padding-16",
              },
              [
                _c("div", { staticClass: "flex-row-center-jx" }, [
                  _c("span", { staticClass: "text-20-black33" }, [
                    _vm._v("高拍仪拍摄"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "margin-left-16 flex-row-center",
                      class: _vm.cameraConnect ? "greenHint" : "redHint",
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.cameraConnect ? "高拍仪已连接" : "高拍仪未连接"
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "greenDot margin-left-4",
                        class: _vm.cameraConnect ? "greenDot" : "redDot",
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex-row-center-jx margin-left-16 disable-select",
                      class: _vm.isConnectingNow
                        ? ""
                        : "hand-icon btn-hover-eb",
                      on: { click: _vm.reconnectCamera },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-refresh icon-12 color-orangeFF",
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "font-size12 color-orangeFF" },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.isConnectingNow ? "连接中.." : "重新连接"
                            )
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("i", {
                  staticClass: "closeBtn el-icon-close hand-icon",
                  on: { click: _vm.closeWindow },
                }),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "photoShowArea disable-select" }, [
              _vm.showVideo
                ? _c("div", { staticClass: "match-parent" }, [
                    _vm.cameraConnect
                      ? _c("div", { staticClass: "match-parent position-r" }, [
                          _c("img", {
                            staticClass: "match-parent",
                            staticStyle: { "object-fit": "contain" },
                            attrs: {
                              src: _vm.cameraData,
                              alt: "",
                              draggable: "false",
                            },
                          }),
                          _vm._v(" "),
                          _vm.cameraConnect
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "flex-row-center font-size16 color-white preHandleBar position-a",
                                  staticStyle: { width: "180px" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex-row-center-jx font-size14 color-white btn-hover-eb hand-icon",
                                      on: {
                                        click: function ($event) {
                                          return _vm.rotate(true)
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "icon-16",
                                        attrs: { src: _vm.rotateLeft, alt: "" },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "margin-left-4",
                                          staticStyle: { "margin-top": "2px" },
                                        },
                                        [_vm._v("左旋转")]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex-row-center-jx font-size14 color-white margin-left-16 btn-hover-eb hand-icon",
                                      on: {
                                        click: function ($event) {
                                          return _vm.rotate(false)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: { "margin-top": "2px" },
                                        },
                                        [_vm._v("右旋转")]
                                      ),
                                      _vm._v(" "),
                                      _c("img", {
                                        staticClass: "icon-16 margin-left-4",
                                        attrs: {
                                          src: _vm.rotateRight,
                                          alt: "",
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ])
                : _c(
                    "div",
                    { staticClass: "match-parent position-r" },
                    [
                      _c("scale-image", {
                        ref: "scaleImage",
                        staticClass: "match-parent",
                        attrs: { "image-url": _vm.curPrePhotoUrl },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex-row-center position-a preClosePos disable-select hand-icon btn-hover-eb",
                          on: { click: _vm.exitPre },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "color-orangeFF font-size14" },
                            [_vm._v("退出预览")]
                          ),
                          _vm._v(" "),
                          _c("i", {
                            staticClass:
                              "el-icon-close color-orangeFF margin-left-4",
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.curPreIndex > 0
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "flex-row-center preLeftBtn position-a",
                              on: {
                                click: function ($event) {
                                  return _vm.switchPrePhoto(false)
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "el-icon-arrow-left color-white font-size30",
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.curPreIndex < _vm.photoInfo.length - 1
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "flex-row-center preRightBtn position-a",
                              on: {
                                click: function ($event) {
                                  return _vm.switchPrePhoto(true)
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "el-icon-arrow-right color-white font-size30",
                              }),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "margin-top-8 flex-row-center-jx padding-h-16" },
              [
                _c(
                  "div",
                  { staticClass: "handleBar flex-row-center-jx flex-between" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "switchPageBtnLeft disable-select flex-col-center",
                        class:
                          _vm.cameraConnect && _vm.curPageIndex > 0
                            ? "btnClick"
                            : "",
                        on: {
                          click: function ($event) {
                            return _vm.switchPageIndex(false)
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "icon-12",
                          attrs: {
                            src:
                              _vm.cameraConnect && _vm.curPageIndex > 0
                                ? _vm.arrowLeftImg
                                : _vm.arrowLeftDisableImg,
                            alt: "",
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-carousel",
                      {
                        staticClass: "flex1",
                        attrs: {
                          arrow: "never",
                          loop: false,
                          "initial-index": 0,
                          "indicator-position": "none",
                          autoplay: false,
                          height: "90px",
                        },
                      },
                      _vm._l(_vm.pages, function (item, index) {
                        return _c(
                          "el-carousel-item",
                          { key: index, staticClass: "match-parent" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "onePagePhoto flex-row flex-between border-box",
                              },
                              _vm._l(_vm.curPagePhoto, function (photo, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass: "bottomPhotoContainer",
                                    class:
                                      _vm.curPrePhotoId === photo.id
                                        ? "selectBorder"
                                        : "holderBorder",
                                  },
                                  [
                                    photo.photoData
                                      ? _c("tiny-photo", {
                                          attrs: { "photo-info": photo },
                                          on: {
                                            onSelectChange: function ($event) {
                                              return _vm.clickPre(photo, index)
                                            },
                                            deleteSelf: function ($event) {
                                              return _vm.deletePhoto(
                                                photo,
                                                index
                                              )
                                            },
                                            reUpload: function ($event) {
                                              return _vm.reUploadPhoto(photo)
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              }),
                              0
                            ),
                          ]
                        )
                      }),
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "switchPageBtnRight disable-select flex-col-center",
                        class: _vm.nextPageIconEnable ? "btnClick" : "",
                        on: {
                          click: function ($event) {
                            return _vm.switchPageIndex(true)
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "icon-12",
                          attrs: {
                            src: _vm.nextPageIconEnable
                              ? _vm.arrowRightImg
                              : _vm.arrowRightDisableImg,
                            alt: "",
                          },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "flex-col-center-jx margin-left-16 flex-between match-parent-h disable-select",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "handlerBtnContainer flex-row-center",
                        class: _vm.allReUploadEnable ? "btnClick" : "",
                        on: { click: _vm.allReUpload },
                      },
                      [
                        _c("img", {
                          staticClass: "icon-12",
                          attrs: {
                            src: _vm.allReUploadEnable
                              ? _vm.refreshImg
                              : _vm.refreshDisableImg,
                            alt: "",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "font-size12 margin-left-4",
                            class: _vm.allReUploadEnable
                              ? "color-gray66"
                              : "color-grayCc",
                          },
                          [_vm._v("全部重试")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "handlerBtnContainer flex-row-center",
                        class: _vm.clearPhotoEnable ? "btnClick" : "",
                        on: { click: _vm.clearPhoto },
                      },
                      [
                        _c("img", {
                          staticClass: "icon-12",
                          attrs: {
                            src: _vm.clearPhotoEnable
                              ? _vm.deleteImg
                              : _vm.deleteDisableImg,
                            alt: "",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "font-size12 margin-left-4",
                            class: _vm.clearPhotoEnable
                              ? "color-gray66"
                              : "color-grayCc",
                          },
                          [_vm._v("清空照片")]
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "flex1 flex-row-center position-r" }, [
              _c(
                "div",
                {
                  staticClass: "flex-row-center disable-select hand-icon",
                  class: _vm.takePhotoEnable
                    ? "takePhotoBtn"
                    : "takePhotoBtnDisable",
                  on: { click: _vm.takePhoto },
                },
                [
                  _c("img", {
                    staticClass: "icon-24",
                    attrs: { src: _vm.cameraIcon, alt: "" },
                  }),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "bottomBtnArea flex-row-center-jx" }, [
                _c(
                  "div",
                  {
                    staticClass: "btnCancel flex-row-center disable-select",
                    on: { click: _vm.closeWindow },
                  },
                  [_vm._v("取消")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "flex-row-center margin-left-16 disable-select",
                    class: _vm.clearPhotoEnable ? "btnSave" : "btnSaveDisable",
                    on: { click: _vm.savePhoto },
                  },
                  [_vm._v("保存\n                    ")]
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.dialogTitle,
                visible: _vm.dialogHint,
                top: "30vh",
                width: "30%",
                "append-to-body": true,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogHint = $event
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "flex-col",
                  attrs: { slot: "default" },
                  slot: "default",
                },
                [
                  _c("div", { staticClass: "flex-col-center-jx" }, [
                    _c("div", { staticClass: "flex-row-center-jx" }, [
                      _c("i", {
                        staticClass: "icon-16",
                        class: _vm.dialogPositive
                          ? "el-icon-warning-outline color-green"
                          : "el-icon-warning color-redFF",
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass:
                            "font-size16 color-black font-bold margin-left-4",
                        },
                        [_vm._v(_vm._s(_vm.dialogSubTitle))]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "font-size12 margin-top-4" }, [
                      _vm._v(_vm._s(_vm.dialogContent)),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "flex-row-re",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _vm.needNe
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "btnSave flex-row-center margin-left-16 disable-select",
                          on: { click: _vm.dialogNe },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.dialogNeText) +
                              "\n                "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.needPo
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "btnCancel flex-row-center disable-select margin-left-16",
                          on: { click: _vm.dialogPo },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.dialogPoText) +
                              "\n                "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }