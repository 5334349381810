<template>
    <div class="match-parent flex-col position-r border-box disable-select" :class="selected?'selectBorder':''">
        <el-image class="tinyPhoto hand-icon" :src="photoData" fit="contain" @click="selectCur"/>
        <el-progress :percentage="percent" :color="progressColor" :stroke-width="2" :show-text="false"/>
        <div class="flex1 flex-row-center" @click="retryNow"
             :class="status === 'failed'?'disable-select btnActive hand-icon retryHover':''">
            <span class="font-size12" :style="hintTextColor">{{hintText}}</span>
            <img v-if="status === 'failed'" :src="refreshImg" class="icon-10 margin-left-4" alt=""/>
        </div>
        <i class="el-icon-error closeIconPos" @click="deleteCurPhoto"/>
    </div>
</template>

<script>
    import refreshImg from '../assets/reftesh.svg'
    import tool from "../tool/tool";

    export default {
        name: "tinyPhoto",
        data() {
            return {
                selected: false,
                status: '',
                photoData: '',
                percent: 0,

                hintText: '上传中..',
                hintTextClass: '',
                refreshImg
            }
        },
        props: {
            photoInfo: {
                type: Object,
            }
        },
        watch: {
            photoInfo: {
                handler(value) {
                    // 解析外部传人的照片信息
                    // console.log("handler-tinyPhoto-Watch-新数据=",value);
                    if (!value) return;
                    let {status, photoData, percent} = value;
                    this.status = status;
                    this.photoData = photoData;
                    this.percent = percent;
                }, immediate: true, deep: true
            }
        },
        computed: {
            progressColor() {
                switch (this.status) {
                    case 'complete':
                        this.hintText = '上传完成';
                        return '#159900';
                    case 'failed':
                        this.hintText = '重试';
                        return '#FF1A1A';
                    default:
                        this.hintText = '上传中..';
                        return '#0564F4';
                }
            },
            hintTextColor() {
                let textColor = '#333333';
                if (this.status !== 'failed') {
                    textColor = this.progressColor;
                }
                return `color:${textColor};`
            }
        },
        methods: {
            async retryNow() {
                if (this.status !== 'failed') return;
                try {
                    await tool.forbidDoubleClick('selectCur');
                    this.$emit('reUpload');
                } catch (e) {
                    console.log("selectCur-用户快速点击");
                }
            },
            async deleteCurPhoto() {
                try {
                    await tool.forbidDoubleClick('selectCur');
                    this.$emit('deleteSelf');
                } catch (e) {
                    console.log("selectCur-用户快速点击");
                }
            },
            async selectCur() {
                try {
                    await tool.forbidDoubleClick('selectCur');
                    this.$emit('onSelectChange');
                } catch (e) {
                    console.log("selectCur-用户快速点击");
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .tinyPhoto {
        width: 100%;
        height: 71%;
    }

    .btnActive {
        background-color: #F5F5F5;

        &:active {
            background-color: #76d4e3;
        }
    }

    .closeIconPos {
        position: absolute;
        top: 2px;
        right: 2px;
        font-size: 20px;
        color: black;
        cursor: pointer;

        &:active {
            color: #EBEBEB;
        }
    }

    .selectBorder {
        border: 1px solid #FF9503;
    }

    .retryHover:hover{
        background-color: rgba(17, 163, 144, 0.6);
    }
</style>