<template>
    <div v-if="showNow" class="back-color-black35 flex-row-center cover">
        <div class="dialogArea back-color-white flex-col">
            <div class="title flex-row-center-jx flex-between padding-16">
                <div class="flex-row-center-jx">
                    <span class="text-20-black33">高拍仪拍摄</span>
                    <div :class="cameraConnect?'greenHint':'redHint'" class="margin-left-16 flex-row-center">
                        <span>{{cameraConnect?'高拍仪已连接':'高拍仪未连接'}}</span>
                        <div :class="cameraConnect?'greenDot':'redDot'" class="greenDot margin-left-4"/>
                    </div>
<!--                    <div v-if="!cameraConnect" :class="isConnectingNow?'':'btn-hover-eb'"-->
                    <div :class="isConnectingNow?'':'hand-icon btn-hover-eb'"
                         class="flex-row-center-jx margin-left-16 disable-select" @click="reconnectCamera">
                        <i class="el-icon-refresh icon-12 color-orangeFF"/>
                        <span class="font-size12 color-orangeFF">{{isConnectingNow?'连接中..':'重新连接'}}</span>
                    </div>
                </div>
                <i class="closeBtn el-icon-close hand-icon" @click="closeWindow"/>
            </div>

            <div class="photoShowArea disable-select">
                <div v-if="showVideo" class="match-parent">
                    <div v-if="cameraConnect" class="match-parent position-r">
                        <img class="match-parent" :src="cameraData" alt="" style="object-fit:contain"
                             draggable="false"/>
                        <div v-if="cameraConnect"
                             class="flex-row-center font-size16 color-white preHandleBar position-a"
                             style="width: 180px">
                            <div class="flex-row-center-jx font-size14 color-white btn-hover-eb hand-icon"
                                 @click="rotate(true)">
                                <img :src="rotateLeft" alt="" class="icon-16"/>
                                <span class="margin-left-4" style="margin-top: 2px">左旋转</span>
                            </div>

                            <div
                                class="flex-row-center-jx font-size14 color-white margin-left-16 btn-hover-eb hand-icon"
                                @click="rotate(false)">
                                <span style="margin-top: 2px">右旋转</span>
                                <img :src="rotateRight" alt="" class="icon-16 margin-left-4"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-else class="match-parent position-r">
                    <scale-image ref="scaleImage" class="match-parent" :image-url="curPrePhotoUrl"/>
                    <div class="flex-row-center position-a preClosePos disable-select hand-icon btn-hover-eb"
                         @click="exitPre">
                        <span class="color-orangeFF font-size14">退出预览</span>
                        <i class="el-icon-close color-orangeFF margin-left-4"/>
                    </div>
                    <div v-if="curPreIndex > 0" class="flex-row-center preLeftBtn position-a"
                         @click="switchPrePhoto(false)">
                        <i class="el-icon-arrow-left color-white font-size30"/>
                    </div>
                    <div v-if="curPreIndex < photoInfo.length-1" class="flex-row-center preRightBtn position-a"
                         @click="switchPrePhoto(true)">
                        <i class="el-icon-arrow-right color-white font-size30"/>
                    </div>
                </div>
            </div>
            <div class="margin-top-8 flex-row-center-jx padding-h-16">
                <div class="handleBar flex-row-center-jx flex-between">
                    <div class="switchPageBtnLeft disable-select flex-col-center" @click="switchPageIndex(false)"
                         :class="cameraConnect&&curPageIndex>0?'btnClick':''">
                        <img :src="cameraConnect&&curPageIndex>0?arrowLeftImg:arrowLeftDisableImg" class="icon-12"
                             alt=""/>
                    </div>
                    <el-carousel arrow="never" :loop="false" :initial-index="0" class="flex1"
                                 indicator-position="none" :autoplay="false" height="90px">
                        <el-carousel-item v-for="(item, index) in pages" :key="index" class="match-parent">
                            <div class="onePagePhoto flex-row flex-between border-box">
                                <div v-for="(photo,index) in curPagePhoto" :key="index" class="bottomPhotoContainer"
                                     :class="curPrePhotoId===photo.id?'selectBorder':'holderBorder'">
                                    <tiny-photo v-if="photo.photoData" :photo-info="photo"
                                                @onSelectChange="clickPre(photo,index)"
                                                @deleteSelf="deletePhoto(photo,index)"
                                                @reUpload="reUploadPhoto(photo)"/>
                                </div>
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                    <div class="switchPageBtnRight disable-select flex-col-center" @click="switchPageIndex(true)"
                         :class="nextPageIconEnable?'btnClick':''">
                        <img :src="nextPageIconEnable?arrowRightImg:arrowRightDisableImg"
                             class="icon-12" alt=""/>
                    </div>
                </div>
                <div class="flex-col-center-jx margin-left-16 flex-between match-parent-h disable-select">
                    <div class="handlerBtnContainer flex-row-center" :class="allReUploadEnable?'btnClick':''"
                         @click="allReUpload">
                        <img :src="allReUploadEnable?refreshImg:refreshDisableImg" class="icon-12" alt=""/>
                        <span :class="allReUploadEnable?'color-gray66':'color-grayCc'"
                              class="font-size12 margin-left-4">全部重试</span>
                    </div>
                    <div class="handlerBtnContainer flex-row-center" :class="clearPhotoEnable?'btnClick':''"
                         @click="clearPhoto">
                        <img :src="clearPhotoEnable?deleteImg:deleteDisableImg" class="icon-12" alt=""/>
                        <span :class="clearPhotoEnable?'color-gray66':'color-grayCc'" class="font-size12 margin-left-4">清空照片</span>
                    </div>
                </div>
            </div>
            <div class="flex1 flex-row-center position-r">
                <div :class="takePhotoEnable?'takePhotoBtn':'takePhotoBtnDisable'"
                     class="flex-row-center disable-select hand-icon" @click="takePhoto">
                    <img :src="cameraIcon" class="icon-24" alt=""/>
                </div>
                <div class="bottomBtnArea flex-row-center-jx">
                    <div class="btnCancel flex-row-center disable-select" @click="closeWindow">取消</div>
                    <div class="flex-row-center margin-left-16 disable-select" @click="savePhoto"
                         :class="clearPhotoEnable?'btnSave':'btnSaveDisable'">保存
                    </div>
                </div>
            </div>
        </div>
        <el-dialog :title="dialogTitle" :visible.sync="dialogHint" top="30vh" width="30%" :append-to-body="true">
            <div slot="default" class="flex-col">
                <div class="flex-col-center-jx">
                    <div class="flex-row-center-jx">
                        <i class="icon-16"
                           :class="dialogPositive?'el-icon-warning-outline color-green':'el-icon-warning color-redFF'"/>
                        <span class="font-size16 color-black font-bold margin-left-4">{{dialogSubTitle}}</span>
                    </div>
                    <span class="font-size12 margin-top-4">{{dialogContent}}</span>
                </div>
            </div>
            <div slot="footer" class="flex-row-re">
                <div v-if="needNe" class="btnSave flex-row-center margin-left-16 disable-select" @click="dialogNe">
                    {{dialogNeText}}
                </div>
                <div v-if="needPo" class="btnCancel flex-row-center disable-select margin-left-16" @click="dialogPo">
                    {{dialogPoText}}
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import refreshImg from '../assets/reftesh.svg'
    import refreshDisableImg from '../assets/refreshDisable.svg'
    import deleteImg from '../assets/delete.svg'
    import deleteDisableImg from '../assets/deleteDisable.svg'
    import arrowLeftImg from '../assets/arrowLeft.svg'
    import arrowLeftDisableImg from '../assets/arrowLeftDisable.svg'
    import arrowRightImg from '../assets/arrowRight.svg'
    import arrowRightDisableImg from '../assets/arrowRightDisable.svg'
    import videoSuitableImg from '../assets/video_suitable.svg'
    import cameraIcon from '../assets/cameraIcon.svg'
    import rotateLeft from '../assets/rotateLeft.svg'
    import rotateRight from '../assets/rotateRight.svg'
    import verify from "../tool/verify";
    import TinyPhoto from "./tinyPhoto";
    import ScaleImage from "./scaleImage";
    import tool from "../tool/tool";

    export default {
        name: "photoScanView",
        components: {ScaleImage, TinyPhoto},
        data() {
            return {
                showNow: false,
                cameraConnect: true,
                // cameraData: null,
                // photoInfo: [],// 所有的图片的信息列表
                pageSize: 0,// 有多少页
                curPageIndex: 0,
                savePageIndex: 0,// 切换页面前保存的页码
                saveSelectIndex: -1,// 切换页面前保存的选中项
                curSelectIndex: -1,
                curPreIndex: -1,// 预览框的
                curPrePhotoId: -1,
                curPrePhotoUrl: '',// 当前预览的图片url
                curSelectPhotoData: null,// 当前预览的图片信息
                curPrePhoto: null,// 当前预览的图片url
                isConnectingNow: false,// 当前是否在重连
                //dialog相关控制
                dialogType: '',
                dialogHint: false,
                dialogPositive: false,
                dialogTitle: '一级标题',
                dialogSubTitle: '二级标题',
                dialogContent: '提示内容',
                needNe: true,
                needPo: true,
                dialogNeText: '否',
                dialogPoText: '否',

                refreshImg, refreshDisableImg, deleteImg, deleteDisableImg, cameraIcon,
                arrowLeftImg, arrowLeftDisableImg, arrowRightImg, arrowRightDisableImg,
                videoSuitableImg, rotateLeft, rotateRight
            };
        },
        props: {
            cameraData: {
                type: String,
            },
            photoInfo: {
                // 所有的图片的信息列表
                type: Array,
            },
        },
        computed: {
            showVideo() {
                return this.curPrePhotoId === -1;
            },
            allReUploadEnable() {
                return this.photoInfo.length > 0 && this.photoInfo.some(photo => photo.status === 'failed');
            },
            clearPhotoEnable() {
                return this.photoInfo.length > 0;
            },
            takePhotoEnable() {
                return this.cameraConnect;
            },
            nextPageIconEnable() {
                // console.log("nextPageIconEnable-this.pageSize=", this.pageSize);
                // console.log("nextPageIconEnable-this.curPageIndex=", this.curPageIndex);
                return this.cameraConnect && this.pageSize > (this.curPageIndex + 1);
            },
            pages() {
                // console.log("pages-this.photoInfo=", this.photoInfo);
                if (verify.isEmptyArray(this.photoInfo)) {
                    return [[{},{},{},{},{},{},{},{},]];
                }
                let totalLength = this.photoInfo.length, pageList = [], curPageList = [], curPageEndIndex;
                let pageCount = Math.ceil(totalLength / 8);
                // console.log("pages-pageCount=", pageCount);
                for (let i = 0; i < pageCount; i++) {
                    if ((i + 1) * 8 > totalLength) {
                        curPageEndIndex = totalLength;
                    } else {
                        // 后面还有一页
                        curPageEndIndex = (i + 1) * 8;
                    }
                    // console.log("pages-i * 8=", i * 8, '-curPageEndIndex=', curPageEndIndex, 'totalLength=', totalLength);
                    curPageList = this.photoInfo.slice(i * 8, curPageEndIndex);
                    // console.log("pages-curPageList=", curPageList);
                    pageList.push(curPageList);
                }
                let lastPageList = pageList[pageCount - 1];// 获得最后一页的数据，不足8的进行空数据补满
                // console.log("pages-lastPageList-old=", lastPageList);
                for (let i = lastPageList.length; i < 8; i++) {
                    lastPageList.push({});
                }
                // console.log("pages-lastPageList=", lastPageList);
                // console.log("pages-pageList=", pageList);
                this.pageSize = pageList.length;
                return pageList;
            },
            curPagePhoto() {
                // if (verify.isEmptyArray(this.pages)) return [];
                console.log("curPagePhoto-this.pages=",this.pages);
                console.log("curPagePhoto-this.pages[this.curPageIndex]=",this.pages[this.curPageIndex]);
                return this.pages[this.curPageIndex];
            },
            photoInfoCopy() {
                return JSON.parse(JSON.stringify(this.photoInfo));
            }
        },
        watch: {
            // curPreIndex: {
            //     handler(index) {
            //         console.log("handler-预览index发生了变化-index=", index);
            //         if (index === -1) {
            //             this.curSelectIndex = -1;
            //             return;
            //         }
            //         // 需要计算index在当前页面位置，和是第几页
            //         this.curPageIndex = Math.floor(index / 8);
            //         this.selectCur(index % 8);
            //     }
            // },
            photoInfoCopy: {
                handler(newValue, oldValue) {
                    console.log("photo发生变化-新值=", newValue);
                    console.log("photo发生变化-旧值=", oldValue);
                    if (verify.isEmptyArray(newValue)) {
                        // 用户清空了数据-重置一下选择项
                        this.curPreIndex = -1;
                        this.curPrePhotoId = -1;
                        this.curPrePhotoUrl = '';
                        return;
                    }
                    if (verify.isEmptyArray(oldValue)) {
                        // 第一次进入-旧值为空-不做处理
                        return;
                    }
                    if (newValue.length < oldValue.length) {
                        // 删除了数据
                        if (this.curPreIndex !== -1 && this.curPrePhotoId !== -1) {
                            // 删除前是有选择某一张图片预览的
                            let findIndex = newValue.findIndex(info => info.id === this.curPrePhotoId);
                            if (findIndex === -1) {
                                // 如果没找到-删的就是预览的图片-退出预览
                                this.exitPre();
                            } else {
                                // 删除的不是正在预览的图片-需要更新index
                                this.curPreIndex = findIndex;
                            }
                        }
                    }
                    // console.log("photo发生变化-当前这页照片数据=", this.curPagePhoto);
                    // console.log("当前选中的照片数据=", this.curPrePhoto);

                }, immediate: true, deep: true
            }
        },
        methods: {
            showView() {
                this.showNow = true;
            },
            hideView() {
                this.showNow = false;
            },
            // ------------------------------------------------------------------------------------------------------------------------
            //     **||
            //  *****||:高拍相关操作
            //     **||
            // ------------------------------------------------------------------------------------------------------------------------
            changeCameraStatus(isConnect) {
                this.isConnectingNow = false;
                this.cameraConnect = isConnect;
            },
            reconnectCamera() {
                if (this.isConnectingNow) return;
                this.isConnectingNow = true;
                this.cameraConnect = false;
                this.doInteraction('reconnectCamera');
            },
            rotate(isLeft) {
                this.doInteraction(isLeft ? 'rotateLeft' : 'rotateRight');
            },
            takePhoto() {
                this.curPreIndex = -1;
                this.curPrePhotoId = -1;
                this.curPrePhotoUrl = "";
                this.doInteraction('takePhoto');
            },
            // ------------------------------------------------------------------------------------------------------------------------
            //     **||
            //  *****||:通用操作
            //     **||
            // ------------------------------------------------------------------------------------------------------------------------
            closeWindow() {
                if (verify.isEmptyArray(this.photoInfo)) {
                    // 没照片直接退出
                    this.doInteraction('exit');
                    this.hideView();
                    return;
                }
                this.showDialog('exit');
            },
            savePhoto() {
                if (!this.clearPhotoEnable) return;
                let haveUploading = this.photoInfo.some(info => info.status === 'uploading');
                if (haveUploading) {
                    this.showDialog('exitButSomeUploading');
                    return;
                }
                let haveFailed = this.photoInfo.some(info => info.status === 'failed');
                if (haveFailed) {
                    this.showDialog('exitButSomeFailed');
                    return;
                }
                this.hideView();
                this.doInteraction('savePhoto');
            },
            clearPhoto() {
                if (!this.clearPhotoEnable) return;
                this.doInteraction('clearAllPhoto');
            },
            showDialog(type) {
                this.needNe = true;
                this.needPo = true;
                switch (type) {
                    case 'exit':
                        this.dialogTitle = '关闭提示';
                        this.dialogSubTitle = '是否退出';
                        this.dialogContent = '已拍照片将自动清空，是否退出？';
                        this.dialogPositive = false;
                        this.dialogNeText = '否';
                        this.dialogPoText = '是';
                        break;
                    case 'exitButSomeFailed':
                        this.dialogTitle = '关闭提示';
                        this.dialogSubTitle = '某些文件上传失败';
                        this.dialogContent = '仍有部分文件上传失败，是否保存已上传的照片并退出？';
                        this.dialogPositive = false;
                        this.dialogNeText = '否';
                        this.dialogPoText = '是';
                        break;
                    case 'exitButSomeUploading':
                        this.dialogTitle = '关闭提示';
                        this.dialogSubTitle = '文件上传中..';
                        this.dialogContent = '文件正在加紧上传中，请您耐心等待！';
                        this.dialogPositive = false;
                        this.needPo = false;
                        this.dialogNeText = '继续等待';
                        break;
                    case 'allFileComplete':
                        this.dialogTitle = '文件上传提示';
                        this.dialogSubTitle = '已全部完成';
                        this.dialogContent = '所有照片均已上传成功,是否退出?';
                        this.dialogPositive = true;
                        break;
                }
                this.dialogType = type;
                this.dialogHint = true;
            },
            dialogNe() {
                this.closeDialog();
            },
            dialogPo() {
                switch (this.dialogType) {
                    case "exit":
                        this.existAndClear();
                        break;
                    case "exitButSomeFailed":
                        console.log("有部分文件上传失败,但是不管");
                        this.closeDialog();
                        this.doInteraction('finishAndDropFailed');
                        this.hideView();
                        break
                }
            },
            closeDialog() {
                this.dialogHint = false;
            },
            existAndClear() {
                this.closeDialog();
                this.hideView();
                this.doInteraction('existAndClearPhoto');
            },
            // ------------------------------------------------------------------------------------------------------------------------
            //     **||
            //  *****||:下方小照片列表操作
            //     **||
            // ------------------------------------------------------------------------------------------------------------------------
            switchPageIndex(isNext) {
                let index = this.curPageIndex;
                if (isNext) {
                    index++;
                } else {
                    index--;
                }
                if (index >= 0 && index < this.pageSize) {
                    this.curPageIndex = index;
                    // if (this.saveSelectIndex !== -1) {
                    //     this.curSelectIndex = index === this.savePageIndex ? this.saveSelectIndex : -2;
                    // }
                }
            },
            // /**
            //  * 选中底部某一张照片进行预览
            //  */
            // selectCur(index) {
            //     this.doInteraction('preScanNow');
            //     if (this.curSelectIndex !== index) {
            //         this.curSelectIndex = index;
            //         this.savePageIndex = this.curPageIndex;
            //         this.saveSelectIndex = index;
            //
            //         this.curSelectPhotoData = this.curPagePhoto[index];
            //         this.curPrePhoto = this.curSelectPhotoData.photoData;
            //     }
            // },
            deletePhoto(photo, index) {
                let photoIndex = this.curPageIndex * 8 + index;
                this.doInteraction('deletePhoto', {photo, photoIndex});
            },
            reUploadPhoto(photo) {
                this.doInteraction('reUploadOne', photo);
            },
            allReUpload() {
                if (!this.allReUploadEnable) return;
                let failedArray = this.photoInfo.filter(photo => photo.status === 'failed');
                this.doInteraction('allReUpload', failedArray);
            },
            /**
             * 点击下方小图片预览
             * @param index 当前页面的位置
             * @param photo 正常的图数据
             */
            clickPre(photo, index) {
                let totalIndex = this.curPageIndex * 8 + index;
                this.preLook(photo, totalIndex);
            },
            /**
             * 点击小图片进行预览
             */
            preLook(photo, index) {
                // 计算总位置-通过watch来修改ui
                // this.curPreIndex = this.curPageIndex * 8 + index;
                console.log("preLook-photo=", photo, index);
                this.curPreIndex = index;
                this.curPrePhotoId = photo.id;
                this.curPrePhotoUrl = photo.photoData;
                // 判断当前预览index还在不在当前页，不再就需要翻页
                if (this.curPreIndex >= (this.curPageIndex + 1) * 8) {
                    // 翻下一页
                    this.curPageIndex++;
                } else if (this.curPreIndex < (this.curPageIndex) * 8) {
                    this.curPageIndex--;
                }
                if (this.$refs['scaleImage']) this.$refs['scaleImage'].suitContainer();
            },
            switchPrePhoto(isNext) {
                let index = this.curPreIndex;
                if (isNext) {
                    index++;
                } else {
                    index--;
                }
                if (index >= 0 && index < this.photoInfo.length) {
                    console.log("switchPrePhoto-index=", index);
                    // this.curPreIndex = index;
                    this.preLook(this.photoInfo[index], index);
                }
            },
            exitPre() {
                this.doInteraction('exitPreScan');
                this.curPrePhotoId = -1;
                this.curPreIndex = -1;
                this.curPrePhotoUrl = '';
            },
            /**
             * 所有文件都已经上传完毕
             */
            onAllFileUpload() {
                this.showDialog('allFileComplete');
            },
            async doInteraction(flag, data = null) {
                try {
                    await tool.forbidDoubleClick('doInteraction', 1000);
                    console.log("doInteraction-flag, data|", flag, data);
                    this.$emit('interaction', {flag, data});
                } catch (e) {
                    console.log("doInteraction-用户快速双击");
                }
            }
        },
    }
</script>

<style lang="scss" scoped>
    .dialogArea {
        border-radius: 1.5vh;
        width: 56vw;
        height: 80vh;
    }

    .greenHint, .redHint {
        font-size: 12px;
        border-radius: 4px;
        height: 24px;
        min-width: 100px;
        padding: 0 8px;
    }

    .greenHint {
        background-color: rgba(21, 153, 0, 0.1);
        color: #159900;
    }

    .redHint {
        background-color: rgba(255, 26, 26, 0.1);
        color: #FF1A1A;
    }

    .greenDot, .redDot {
        width: 11px;
        height: 11px;
        border-radius: 50%;
        border: 1px solid white;
    }

    .greenDot {
        background-color: #159900;
    }

    .redDot {
        background-color: #FF1A1A;
    }

    .closeBtn {
        width: 12px;
        height: 12px;
        color: #666666;
        font-weight: bold;

        &:active {
            color: #159900;
        }
    }

    .photoShowArea {
        width: 100%;
        height: 52vh;
        background-color: #EBEBEB;
    }

    .handleBar {
        flex: 1;
        height: 90px;
    }

    .handlerBtnContainer {
        border-radius: 4px;
        background-color: #F7F7F7;
        height: 40px;
        width: 88px;
        padding: 0 12px;
        cursor: pointer;
    }

    .btnClick:active {
        background-color: #51cfe3;
    }

    @mixin switchPageBtn {
        width: 30px;
        min-height: 90px;
        background: #F5F5F5;
    }

    .switchPageBtnLeft {
        @include switchPageBtn;
        border-radius: 4px 0 0 4px;
    }

    .switchPageBtnRight {
        @include switchPageBtn;
        border-radius: 0 4px 4px 0;
    }

    .takePhotoBtn, .takePhotoBtnDisable {
        width: 56px;
        height: 56px;
        border-radius: 50%;
        background-color: #FF9503;
    }

    .takePhotoBtnDisable {
        opacity: 0.6;
    }

    .takePhotoBtn {
        /*background-color: #FF9503;*/
        &:active {
            background-color: rgba(255, 149, 3, 0.1);
        }
    }

    .bottomBtnArea {
        position: absolute;
        right: 16px;
        margin: 0 auto;
    }

    @mixin bottomBtn {
        width: 80px;
        height: 32px;
        border-radius: 3px;
        cursor: pointer;

        &:active {
            background-color: #EBEBEB;
        }
    }

    .btnCancel {
        @include bottomBtn;
        color: #FF9503;
        background-color: rgba(255, 149, 3, 0.1);
    }

    .btnSave, .btnSaveDisable {
        @include bottomBtn;
        color: white;
        background-color: #FF9503;
    }

    .btnSaveDisable {
        opacity: 0.6;

        &:active {
            background-color: #FF9503;
        }
    }

    .onePagePhoto {
        height: 100%;
        width: 100%;
        padding: 0 1%;
        background-color: white;
    }

    .bottomPhotoContainer {
        height: 86px;
        width: 11.1%;
        background-color: #F5F5F5;
    }

    .selectBorder {
        border: 2px solid #FF9503;
    }

    .holderBorder {
        border: 2px solid white;
    }

    .preClosePos {
        right: 16px;
        top: 16px;
        padding: 2px 4px;
        border-radius: 4px;
        z-index: 3;
        background-color: white;
    }

    .preLeftBtn, .preRightBtn {
        height: 100%;
        width: 50px;
        z-index: 2;
        background-color: rgba(0, 0, 0, 0.2);

        &:active {
            background-color: rgba(209, 244, 244, 0.2);
        }
    }

    .preLeftBtn {
        left: 0;
        top: 0;
    }

    .preRightBtn {
        right: 0;
        top: 0;
    }

</style>