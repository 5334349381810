// 工具方法类
const util ={
    forbidTimeMap: {},// 放双击需要记录相关时间
    /**
     * @param btnFlag 按钮标记-可以用方法名来代表
     * @param forbidInterval
     * @returns {Promise<unknown>}
     */
    forbidDoubleClick(btnFlag, forbidInterval = 400) {
        // 获取当前按钮-上一次点击时间
        let lastTime = this.forbidTimeMap[btnFlag], curTime = Date.now();
        if (!lastTime) {
            // 如果找不到这个时间-说明是第一次点击-记录当前时间
            this.forbidTimeMap = {};// 清空时间缓存-移除了上一次其他按钮的记录
            this.forbidTimeMap[btnFlag] = curTime;
            lastTime = curTime;
        }
        // 点击了按钮
        return new Promise((resolve, reject) => {
            let timeInterval = curTime - lastTime;
            if (timeInterval > forbidInterval || timeInterval === 0) {
                // 第一次点击或已经超过2秒-允许其点击-并记录该时间
                this.forbidTimeMap[btnFlag] = curTime;
                resolve()
            } else {
                // 在时间内-拒绝其点击-并且不更新上次点击时间
                reject(Error("请勿频繁点击！"));
            }
        });
    },
    /**
     * 等待一小会
     * @param waitTime 需要等待多少秒
     */
    waitSomeTime(waitTime = 1) {
        return new Promise(resolve => {
            setTimeout(() => {resolve()}, waitTime * 1000)
        });
    },
}
export default {...util};