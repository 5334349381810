var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "match-parent flex-col position-r border-box disable-select",
      class: _vm.selected ? "selectBorder" : "",
    },
    [
      _c("el-image", {
        staticClass: "tinyPhoto hand-icon",
        attrs: { src: _vm.photoData, fit: "contain" },
        on: { click: _vm.selectCur },
      }),
      _vm._v(" "),
      _c("el-progress", {
        attrs: {
          percentage: _vm.percent,
          color: _vm.progressColor,
          "stroke-width": 2,
          "show-text": false,
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "flex1 flex-row-center",
          class:
            _vm.status === "failed"
              ? "disable-select btnActive hand-icon retryHover"
              : "",
          on: { click: _vm.retryNow },
        },
        [
          _c("span", { staticClass: "font-size12", style: _vm.hintTextColor }, [
            _vm._v(_vm._s(_vm.hintText)),
          ]),
          _vm._v(" "),
          _vm.status === "failed"
            ? _c("img", {
                staticClass: "icon-10 margin-left-4",
                attrs: { src: _vm.refreshImg, alt: "" },
              })
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c("i", {
        staticClass: "el-icon-error closeIconPos",
        on: { click: _vm.deleteCurPhoto },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }