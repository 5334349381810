import './css/form-style.scss'
import ElementUI from 'element-ui';
import photoScanView from "./components/photoScanView";

const vueComponent = {
    install: (app) => {
        app.use(ElementUI);
        app.config.productionTip = false;

        app.component('photoScanView', photoScanView)
    }
}
export default vueComponent;

