<template>
    <div id="container" class="match-parent imageContainer position-r" @dragover="$event.preventDefault()">
        <el-image class="match-parent imageStyle" :src="imageUrl" fit="contain" draggable="true"
                  @dragstart="onDragStart" @drag="onDragNow" :style="picPosition"/>
        <div class="flex-row-center font-size16 color-white preHandleBar border-box position-a">
            <div :style="{visibility:curScaleRatio>10?'visible':'hidden'}"
                 class="preHandBtn hand-icon btn-hover-eb flex-row-center" @click="scaleImg(false)">
                <i class="el-icon-minus color-white"/>
            </div>
            <span class="margin-h-4">{{curScaleRatio}}%</span>
            <div :style="{visibility:curScaleRatio<200?'visible':'hidden'}"
                 class="preHandBtn hand-icon btn-hover-eb flex-row-center" @click="scaleImg(true)">
                <i class="el-icon-plus color-white"/>
            </div>
            <div class="flex-row-center margin-h-16 btn-hover-eb hand-icon" @click="suitContainer">
                <span class="font-size12" style="white-space: nowrap">适合尺寸</span>
                <img :src="videoSuitableImg" alt="" class="icon-16 margin-left-4"/>
            </div>

        </div>
    </div>
</template>

<script>
    import videoSuitableImg from '../assets/video_suitable.svg'
    import verify from "../tool/verify";

    export default {
        name: "scaleImage",
        data() {
            return {
                curScaleRatio: 100,
                width: 100,
                height: 100,
                top: 0,
                left: 0,
                videoSuitableImg
            }
        },
        props: {
            imageUrl: {
                type: String,
            }
        },
        computed: {
            picPosition() {
                return {
                    width: `${this.width}px`,
                    height: `${this.height}px`,
                    top: `${this.top}px`,
                    left: `${this.left}px`,
                }
            },
        },
        mounted() {
            let container = document.querySelector('#container');
            if (verify.isEmpty(container)) {
                this.width = 698;
                this.height = 525;
                return;
            }
            this.containerWidth = container.offsetWidth;
            this.containerHeight = container.offsetHeight;
            this.width = this.containerWidth;
            this.height = this.containerHeight;
        },
        methods: {
            onDragStart(event) {
                this.lastEvent = event
                // 设置传输数据-火狐浏览器必须要这么搞才行
                event.dataTransfer.setData('myKey', 'photoDrag');
                console.log("onDragStart-event=", event);
            },
            onDragNow(event) {
                let moveX = event.clientX - this.lastEvent.clientX;
                let moveY = event.clientY - this.lastEvent.clientY;
                // console.log("onDragNow-x移动量=",moveX,event);
                // console.log("onDragNow-y移动量=",moveY);
                if (event.clientX === 0 && event.clientY === 0) {
                    // x,y位置都变成0了，表示拖出去了-直接取消移动即可
                    return;
                }
                if (Math.abs(moveX) > 300 || Math.abs(moveY) > 300) return;
                console.log("onDragNow-进行位移-x,y=", Math.abs(moveX), Math.abs(moveY));
                console.log("onDragOut-this.width=", this.width, "this.height=", this.height, "this.left=", this.left, "this.top=", this.top);
                if (Math.abs(this.left) > this.containerWidth-10 || Math.abs(this.top) > this.containerHeight-10) {
                    this.suitContainer();
                    return;
                }
                this.left += moveX;
                this.top += moveY;
                this.lastEvent = event
            },
            /**
             * 图片缩放
             * @param isPlus 是否放大
             */
            scaleImg(isPlus) {
                // if (this.curScaleRatio >= 200 || this.curScaleRatio <= 10) return;
                if (isPlus) {
                    this.width *= 1.1;
                    this.height *= 1.1;
                    this.curScaleRatio += 10;
                } else {
                    this.width *= 0.9;
                    this.height *= 0.9;
                    this.curScaleRatio -= 10;
                }
            },
            suitContainer() {
                this.width = this.containerWidth;
                this.height = this.containerHeight;
                this.top = 0;
                this.left = 0;
                this.curScaleRatio = 100;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .imageContainer {
        overflow: hidden;
    }

    .imageStyle {
        cursor: url(//img0.bdstatic.com/img/image/grabhand.cur), move;
    }

    .preHandBtn {
        width: 36px;
        height: 20px;
        border-radius: 8px;

        &:active {
            background-color: #EBEBEB;
        }
    }
</style>