var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "match-parent imageContainer position-r",
      attrs: { id: "container" },
      on: {
        dragover: function ($event) {
          return $event.preventDefault()
        },
      },
    },
    [
      _c("el-image", {
        staticClass: "match-parent imageStyle",
        style: _vm.picPosition,
        attrs: { src: _vm.imageUrl, fit: "contain", draggable: "true" },
        on: { dragstart: _vm.onDragStart, drag: _vm.onDragNow },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "flex-row-center font-size16 color-white preHandleBar border-box position-a",
        },
        [
          _c(
            "div",
            {
              staticClass: "preHandBtn hand-icon btn-hover-eb flex-row-center",
              style: {
                visibility: _vm.curScaleRatio > 10 ? "visible" : "hidden",
              },
              on: {
                click: function ($event) {
                  return _vm.scaleImg(false)
                },
              },
            },
            [_c("i", { staticClass: "el-icon-minus color-white" })]
          ),
          _vm._v(" "),
          _c("span", { staticClass: "margin-h-4" }, [
            _vm._v(_vm._s(_vm.curScaleRatio) + "%"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "preHandBtn hand-icon btn-hover-eb flex-row-center",
              style: {
                visibility: _vm.curScaleRatio < 200 ? "visible" : "hidden",
              },
              on: {
                click: function ($event) {
                  return _vm.scaleImg(true)
                },
              },
            },
            [_c("i", { staticClass: "el-icon-plus color-white" })]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "flex-row-center margin-h-16 btn-hover-eb hand-icon",
              on: { click: _vm.suitContainer },
            },
            [
              _c(
                "span",
                {
                  staticClass: "font-size12",
                  staticStyle: { "white-space": "nowrap" },
                },
                [_vm._v("适合尺寸")]
              ),
              _vm._v(" "),
              _c("img", {
                staticClass: "icon-16 margin-left-4",
                attrs: { src: _vm.videoSuitableImg, alt: "" },
              }),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }